import React, { useState } from 'react'

function useFormValidation(initialState: any) {
    const [values, setValues] = useState(initialState)

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        })
    }
    return { values, handleChange }
}

export default useFormValidation
