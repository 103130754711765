import React from 'react'
import Logo from '../../images/ie-logo.svg'
import './Login.scss'

const Login: React.FC = ({ children }) => {
    return (
        <div className="login-wrapper">
            <div className="login">
                <div className="login__header">
                    <img src={Logo} alt="(i.e.,)" />
                    <h1>
                        Signature Generator<span>.</span>
                    </h1>
                </div>
                <div className="login__children">{children}</div>
            </div>
        </div>
    )
}
export default Login
