import React from 'react'
import './LoginError.scss'

export const LoginError = () => {
  return (
    <div className="login-error">
      <p>Something went wrong when signing in</p>
      <p>Please refresh the page and try again</p>
    </div>
  )
}
