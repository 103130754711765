import { useLocalStorageValue } from '@react-hookz/web';
import { GoogleLogin, googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import Form from './components/Form/Form';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import { LoginError } from './components/LoginError/LoginError';
import { GOOGLE } from './config';
import { User } from './types/user';
import { decodeCredential } from './utils/decodeCredential';


const App = () => {
    const [isLoginErrorVisible, setLoginErrorVisible] = React.useState(false)

    const userState = useLocalStorageValue<User>('user')

    // Unix time is the number of seconds that have elapsed since January 1, 1970.
    // https://en.wikipedia.org/wiki/Unix_time
    // We divide the Date.now() value by 1000 to convert from milliseconds to seconds.
    const unixTime = Math.floor(Date.now() / 1000)

    // Ignore the saved user state if the user's session has expired.
    // This has the effect of forcing the user to sign in again.
    const user = userState.value && userState.value.expiresAt > unixTime
        ? userState.value
        : undefined

    return (
        <GoogleOAuthProvider clientId={GOOGLE.clientID}>
            <div className="App">
                {isLoginErrorVisible && ( <LoginError />)}
                {!user && !isLoginErrorVisible && (
                    <Login>
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                const decodedProfile = decodeCredential(credentialResponse)
                                if (decodedProfile) {
                                    userState.set(decodedProfile)
                                    setLoginErrorVisible(false)
                                } else {
                                    userState.remove()
                                    setLoginErrorVisible(true)
                                }
                            }}
                            onError={() => {
                                userState.remove()
                                setLoginErrorVisible(true)
                            }}
                        />
                    </Login>
                )}
                {user && !isLoginErrorVisible && (
                    <>
                        <Header user={user}>
                            <button
                                onClick={() => {
                                    googleLogout()
                                    userState.remove()
                                }}>
                                    Sign out
                                </button>
                         </Header>
                        <Form
                             defaultEmail={user.email}
                             defaultName={user.name}
                        />
                    </>
                )}
            </div>
        </GoogleOAuthProvider>
    )
}
export default App
