import React from 'react'
import User from '../User/User'
import { User as UserProps } from '../../types/user'
import './Header.scss'

type HeaderProps = {
    user: UserProps
}
const Header: React.FC<HeaderProps> = ({ user, children }) => {
    return (
        <header className="header">
            <div className="header__container">
                <div className="header__logo">
                    <h1>
                        <img
                            alt="(i.e.,)"
                            src="/img/ie-logo.png"
                            className="logo"
                            width="85"
                        />{' '}
                        Signature Generator<span>.</span>
                    </h1>
                </div>
                <div className="header__sign-out">
                    {user && <User {...user} />}
                    {children}
                </div>
            </div>
        </header>
    )
}

export default Header
