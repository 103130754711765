import React, { useRef, useState } from 'react'
import useFormValidation from '../../hooks/useFormValidation'
import Signature from '../Signature/Signature'
import './Form.scss'

interface FormProps {
    defaultName: string | null
    defaultEmail: string | null
}

const Form: React.FC<FormProps> = ({ defaultName, defaultEmail }) => {
    const formRef = useRef(null)
    const INITIAL_STATE = {
        name: defaultName,
        email: defaultEmail,
        title: '',
        phone: '+61 390 011 700',
        mobile: '+61 400 000 000',
    }
    const { values, handleChange } = useFormValidation(INITIAL_STATE)
    const [isCopied, setIsCopied] = useState(false)
    const copySignature = () => {
        const copyHandler = function(event: ClipboardEvent) {
            const element = document.getElementById('generated-signature')

            // e.clipboardData is initially empty, but we can set it to the
            // data that we want copied onto the clipboard.
            if (event.clipboardData && element) {
                event.clipboardData.setData('text/plain', element.innerText)
                event.clipboardData.setData('text/html', element.innerHTML)

                // This is necessary to prevent the current document selection from
                // being written to the clipboard.
                event.preventDefault()
            }
        }
        // replace the copy event with one setting our custom content
        document.addEventListener('copy', copyHandler)

        // execute the copy command
        document.execCommand('copy')

        // remove our event listener
        document.removeEventListener('copy', copyHandler)
        setIsCopied(true)
        setTimeout(function() {
            setIsCopied(false)
        }, 1000)
    }
    return (
        <div className="form">
            <div className="form__wrapper">
                <div className="form__details">
                    <h2>1. Fill in your details</h2>
                    <form ref={formRef}>
                        <div className="row">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                onChange={handleChange}
                                name="name"
                                value={values.name}
                                autoComplete="off"
                                placeholder="e.g. John Doe"
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                onChange={handleChange}
                                name="title"
                                value={values.title}
                                placeholder="e.g. Project Manager"
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                onChange={handleChange}
                                name="email"
                                value={values.email}
                                placeholder="e.g. john@ie.com.au"
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="tel"
                                onChange={handleChange}
                                name="phone"
                                value={values.phone}
                                placeholder="e.g. +61 390 011 700"
                            />
                        </div>
                        <div className="row">
                            <label htmlFor="mobile">Mobile</label>
                            <input
                                type="tel"
                                onChange={handleChange}
                                name="mobile"
                                value={values.mobile}
                                placeholder="e.g. +61 400 000 000"
                            />
                        </div>
                    </form>
                </div>
                <div className="form__signature">
                    <h2>2. Your generated signature</h2>
                    <Signature
                        name={values.name}
                        email={values.email}
                        title={values.title}
                        phone={values.phone}
                        mobile={values.mobile}
                    />
                    <h2>3. Copy the signature</h2>
                    <button onClick={copySignature}>
                        {!isCopied
                            ? 'Copy Signature to Your Clipboard!'
                            : 'Copied!'}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default Form
