import React from 'react'
import { User as UserProps } from '../../types/user'
import './User.scss'

const User: React.FC<UserProps> = ({ name, email, image }) => {
    return (
        <div className="user">
            <div className="user__details">
                <div className="user__name">{name} </div>
                <div className="user__email">{email} </div>
            </div>
            <div className="user__image">
                <img src={image} alt={name} />
            </div>
        </div>
    )
}

export default User
