import jwt_decode from "jwt-decode"
import { CredentialResponse } from "@react-oauth/google";
import { User } from "../types/user";

interface Credential {
  email: string
  name: string
  picture?: string
  exp: number
}

const isCredential = (value: unknown): value is Credential => {
  return typeof value === 'object' && value !== null &&
    'email' in value &&  typeof value.email === 'string' &&
    'name' in value &&  typeof value.name === 'string' &&
    'exp' in value && typeof value.exp === 'number';
};

export const decodeCredential = (credentialResponse: CredentialResponse): User | undefined => {
  const credential = credentialResponse.credential && jwt_decode(credentialResponse.credential)

  if (isCredential(credential)) {
    return {
      email: credential.email,
      name: credential.name,
      image: credential.picture || '',
      expiresAt: credential.exp
    }
  } else {
    console.error('Returned type is not Credential')
    return undefined
  }
};
